import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    { path: '/'         , redirect: '/cv' },
    { path: '/cv'       , component: () => import('../views/Resume.vue') },
    { path: '/projects' , component: () => import('../views/Projects.vue') },
    { path: '/teacher'  , component: () => import('../views/Teacher.vue') },
    { path: '/designs'  , component: () => import('../views/Designs.vue') },
    { path: '/frontend' , component: () => import('../views/Frontend.vue') },
    { path: '/photos'   , component: () => import('../views/Photos.vue') },
    { path: '/websites' , component: () => import('../views/Webdesigns.vue') },
    { path: '/docwriter', component: () => import('../views/Docwriter.vue') },
    { path: '/about'    , component: () => import('../views/About.vue') },
]

const router = new VueRouter({
    routes
})

export default router
