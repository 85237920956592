<template>
    <q-layout view="lHh Lpr lFf">
        <q-header elevated class="glossy">
            <q-toolbar>
                <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Menu" icon="menu" />

                <q-toolbar-title>
                    Welcome on Pierre Vannieuwerburgh's web site
                </q-toolbar-title>

                <div>Last update: {{ lastupdate }}</div>
            </q-toolbar>
        </q-header>

        <q-drawer v-model="leftDrawerOpen" show-if-above bordered content-class="bg-grey-2" >
            <q-list>
                <q-item clickable tag="a" href="/#/cv">
                    <q-item-section avatar>
                        <q-icon name="school" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>cv</q-item-label>
                        <q-item-label caption>Studies, languages and career path.</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item-label header>Explore some chalenges I took up...</q-item-label>
                <q-item clickable tag="a" href="/#/frontend">
                    <q-item-section avatar>
                        <q-icon name="chat" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>As web developer..</q-item-label>
                        <q-item-label caption>Some webapps of my own, professional or private.</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item clickable tag="a" href="/#/websites">
                    <q-item-section avatar>
                        <q-icon name="code" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>As web designer..</q-item-label>
                        <q-item-label caption>A few web sites I created or actively collaborated to.</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item clickable tag="a" href="/#/designs">
                    <q-item-section avatar>
                        <q-icon name="forum" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>As designer..</q-item-label>
                        <q-item-label caption>Logos and flyers.</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item clickable tag="a" href="/#/docWriter">
                    <q-item-section avatar>
                        <q-icon name="forum" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>As doc writer..</q-item-label>
                        <q-item-label caption>Documents and blog posts</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item clickable tag="a" href="/#/teacher">
                    <q-item-section avatar>
                        <q-icon name="forum" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>As teacher/mentor..</q-item-label>
                        <q-item-label caption>Teacher and speaker performances</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item clickable tag="a" href="/#/photos">
                    <q-item-section avatar>
                        <q-icon name="forum" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>As photograph..</q-item-label>
                        <q-item-label caption>A few fancy pictures taken here and there.</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
        </q-drawer>

        <q-page-container>
            <router-view></router-view>
        </q-page-container>
    </q-layout>
</template>

<script>

export default {
    name: 'LayoutDefault',

    components: {},

    data () {
        return {
        leftDrawerOpen: false,
        lastupdate: '10-Nov-2020',
        }
    }
}
</script>

<style>
</style>
